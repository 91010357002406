/* Reset some default styles */
body,
h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

.des_p {
  color: #007bff;
  font-size: medium;
}
/* Set a background color and font styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
}

/* Style the header section */
.App-header {
  background-color: #007bff;
  color: #fff;
  text-align: center;
  padding: 2rem;
}

/* Style the main content section */
App {
  max-width: 960px;
  margin: 0 auto;
  padding: 1rem;
}

/* Style individual sections (About, Portfolio, Contact) */
section {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1.5rem;
}

/* Style headings and paragraphs within sections */
h2 {
  color: #007bff;
  margin-bottom: 1rem;
}

/* Style the portfolio items */
.Portfolio-item {
  border: 1px solid #ddd;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.Portfolio-item img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Style contact links */
.Contact a {
  display: inline-block;
  margin-right: 1rem;
  text-decoration: none;
  color: #007bff;
}

/* Style the footer */
footer {
  text-align: center;
  padding: 1rem;
  background-color: #007bff;
  color: #fff;
  font-size: 0.8rem;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  /* Adjust the main content width for smaller screens */
  main {
    padding: 0.5rem;
  }

  /* Make the portfolio items stack on smaller screens */
  .Portfolio-item {
    width: 100%;
    margin-right: 0;
  }

  /* Center-align contact links */
  .Contact a {
    display: block;
    text-align: center;
    margin: 0.5rem auto;
  }
}


/* below are the css for the app sidebar */
/* Style the sidebar (navigation bar) */
.App-sidebar {
  position: fixed;
  top: 0;
  height: 100%;
  width: 150px;
  background-color: #007bff;
  color: #fff;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;
  transition: left 0.3s; /* Smooth transition for sliding in/out */
}

/* Adjust the main content to push it away from the sidebar */
.App-main {
  /* margin-left: 200px; /* Width of the sidebar */
  padding: 20px;
  transition: margin-left 0.3s; /* Smooth transition for main content movement */
}

/* Style sidebar links */
.App-sidebar ul {
  list-style: none;
  padding: 0;
}

.App-sidebar ul li {
  margin-bottom: 10px;
}

.App-sidebar a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  display: block;
  padding: 10px;
  transition: background-color 0.3s;
}

.App-sidebar a:hover {
  background-color: #555;
}

.App.open-sidebar .App-sidebar {
  left: 0;
}

/* Styling for the toggle button */
.sidebar-toggle {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
  display: none; /* Initially hidden */
}

/* Show the button when the sidebar is hidden */
.App:not(.open-sidebar) .sidebar-toggle {
  display: block;
}

/* Show the sidebar when the open class is applied */
.App-sidebar.open {
  left: 0;
}

/* Responsive design for smaller screens */
@media (max-width: 100%) {
  .App-sidebar {
    left: -180px; /* Hidden by default */
  }

  /* Adjust the sidebar's width when open */
  .App.open-sidebar .App-sidebar {
    width: 180px;
  }

  .App-main {
    margin-left: 0; /* No margin for main content */
  }
}
