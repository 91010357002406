/* Your existing styles */

/* Media query for screens smaller than 768px (adjust the value as needed) */
@media (max-width: 768px) {
    .ant-layout-content {
        display: flex;
        flex-direction: column;
    }

    .ant-layout-content>div {
        width: 100%;
    }
}