.events {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .notes-month {
    font-size: 28px;
    text-align: center;
  }
  .notes-month section {
    font-size: 28px;
  }




  /* ---*/
    * {
      /* letter-spacing: 2px; */
      font-family: 'Univers Next';
    }
  
    /*
  .CalendarPage {
    display: flex;
    flex-direction: row;
  }
  */
    /*
  .CalendarPage {
      display: grid;
      grid-template-columns: 1fr 5fr;
      grid-template-rows: 1.5fr 1fr;
      grid-gap: 1rem;
      grid-template-areas:
          "Navibar calendar"
          "Navibar ExpiredcustomerList";
  }
  */
  
    .NavBar {
      /*grid-area: Navibar;*/
      position: fixed;
      z-index: 1;
      /* Stay on top */
      top: 0;
  
    }
  
    /*
  .full-calendar-wrapper {
      grid-area: calender;
  }
  .ExpiredcustomerList {
      grid-area: ExpiredcustomerList;
  }
  
  .pageContent {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
  }
  */
  
    .CalendarPage .NavBar {
      height: 100%;
    }
  
    .calendarpagecontent {
      margin-left: 280px;
      display: flex;
      flex-direction: column;
    }
  
    .full-calendar-wrapper .AddEvent-buttom {
      position: absolute;
      right: 50px;
      top: 15px;
      z-index: 100;
    }
  
    .full-calendar-wrapper .AddEvent-buttom .btn-custom {
      background-color: rgb(226, 139, 139);
    }
  
    .ui.card {
      width: 100% !important;
    }
  
    .ui.segment {
      border-radius: 2px;
      background-color: #ffffff;
      box-shadow: 0 2px 20px 0 rgba(185, 188, 210, 0.2);
      border: solid 1px #e8e8ec;
    }
  
    .full-calendar-wrapper {
      width: 100%;
    }
  
    .full-calendar-wrapper .rbc-calendar {
      min-height: 85vh;
      background-color: #ffffff;
    }
  
    .full-calendar-wrapper .rbc-calendar .rbc-toolbar {
      margin-bottom: 25px;
    }
  
    .full-calendar-wrapper .rbc-calendar .rbc-toolbar .rbc-btn-group button {
      cursor: pointer;
      border-radius: 4px;
      margin-left: 8px;
      font-size: 10px;
      background-color: #e4d6d6;
      color: #8a083e;
      border: none;
      outline: none;
      box-shadow: none;
    }
  
    .full-calendar-wrapper .rbc-calendar .rbc-toolbar .rbc-btn-group button.rbc-active {
      background-color: #c83c3c;
      color: #ffffff;
      outline: none;
    }
  
    .full-calendar-wrapper .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
      font-size: 18px;
      color: #4a4a4a;
      flex-grow: 1;
    }
  
    .full-calendar-wrapper .rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  
    .full-calendar-wrapper .rbc-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header span {
      font-size: 14px;
      color: #4a4a4a;
    }
  
    .full-calendar-wrapper .rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell {
      text-align: left;
      font-size: 14px;
      color: #4a4a4a;
      padding: 8px;
    }

.calendars {
  display: grid;
  row-gap: 10rem;
}

.smallPrettyButton {
  font-size: 15px !important;
  padding: 5px 10px !important;
  border-radius: 5px;
  background-color: #ccc;
  /* Green background */
  color: black;
  /* White text */
  border: none;
  transition: background-color 0.3s, transform 0.2s;
  width: 200px;
  height: 50px;
}

.smallPrettyButton:hover {
  background-color: #ccc;
  /* Darker green */
  transform: translateY(-2px);
  /* Slight lift */
}


body {
  font-family: 'Roboto', sans-serif;
  /* Using Google's Roboto font */
  line-height: 1.6;
  /* Improving line height for better readability */
  color: #333;
  /* Darker font color for better contrast */
}

button {
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 2.5em;
  font-weight: 300;
  color: #353131;
  text-align: center;
  margin-bottom: 20px;
}

/* Custom button styles */

/* Modal content styles */
.modal-content {
  max-width: 600px;
  margin: 0 auto;
}

/* Input and Form Item styles */
.ant-form-item {
  margin-bottom: 16px;
}

.ant-input,
.ant-picker,
.ant-radio-group {
  border-radius: 5px;
}