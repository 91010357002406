.form-header {
    width: 100%;
    color: #fff;
    text-align: center;
    padding: 0.3rem;
}
/* Center the form horizontally and vertically */
.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Make it full height of the viewport */
}
  /* Style the form elements */
.ant-form {
    width: 100%;
    height: 100%;
    max-width: 400px; /* Adjust the maximum width as needed */
    padding: 20px;
    background-color: #f0f0f0; /* Background color for the form */
    border: 1px solid #ccc; /* Add a border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a shadow */
  }
  
  /* Style form labels */
  .ant-form-item-label {
    white-space: nowrap; /* Prevent label text from wrapping */
  }
  
  /* Style form input fields */
  .ant-input,
  .ant-select,
  .ant-datepicker,
  .ant-input-number,
  .ant-upload {
    width: 100%;
    margin-bottom: 16px; /* Add space between form elements */
  }
  
  /* Style form submit button */
  .ant-btn {
    width: 100%;
  }
  
  /* Style form error messages */
  .ant-form-item-explain-error {
    color: red; /* Customize error message color */
  }
  
  /* Add additional styles as needed */
  
