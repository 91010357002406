.online-resume {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  transition: margin-left 0.3s;
}

.resume-container {
  display: flex;
  align-items: center;
  background-color: #ccc;
  color: #fff;
  padding: 20px;
  flex-wrap: wrap;
}

.resume-header {
  flex-basis: 30%;
}

.short-intro {
  flex-grow: 1;
  text-align: center;
  padding: 20px;
}

.photo-container {
  flex-basis: 30%;
  text-align: right;
  padding: 20px;
}

/* Responsive Design */
@media only screen and (max-width: 768px) {

  .resume-header,
  .short-intro,
  .photo-container {
    flex-basis: 100%;
    text-align: center;
  }

  .photo-container {
    order: -1;
    /* This will make the photo appear at the top on smaller screens */
  }
}

section {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  /* Resume headings */
  h1 {
    font-size: 24px;
    color: #333;
    margin-top: 10px;
  }
  
  /* Resume sections */
  p {
    font-size: 16px;
    margin: 10px 0;
  }
  
  /* Education and experience sections */
  strong {
    font-weight: bold;
  }
  
  /* Date and location */
  p small {
    font-size: 14px;
    color: #777;
  }
  
  /* Divider lines */
  hr {
    border: 1px solid #ccc;
    margin: 20px 0;
  }
  
  /* Links */
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  

/* Media query for screens smaller than 768px (adjust the value as needed) */
@media (max-width: 768px) {
  .responsive-card .ant-card-head-title {
    /* font-size: 1.2rem; */
    /* Adjust font size for smaller screens */
    white-space: normal;
    /* Allow the title to wrap onto a new line */
  }
}
