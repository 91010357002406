/* Footer container */
/* .site-footer {
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100vh;
    position: relative;
} */

/* Footer content */
.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

  /* Styling for the footer links */
.footer-links {
    margin-bottom: 10px;
}

.footer-links a {
    margin-right: 10px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

  /* Styling for the copyright information */
.footer-info {
    font-size: 14px;
    opacity: 0.7;
}
