.profile-container {
    background-color: #fff;
    /* White background */
    border: 1px solid #e0e0e0;
    /* Light grey border */
    border-radius: 8px;
    /* Rounded corners */
    overflow: hidden;
    /* Make sure child elements don't overflow */
    display: flex;
    justify-content: space-between;
    padding: 20px;
    /* Adjusted padding */
}

.profile {
    background-color: #f0f0f0;
    /* Light grey background for the entire Content area */
    padding: 20px;
    /* Ensure there’s some space around the profile-container */
}

.profile-header {
    flex: 1;
    padding-right: 20px;
    flex-basis: 30%;
}

.profile-intro {
    /* flex: 1;
    padding: 0 20px; */
    flex-grow: 1;
    text-align: center;
    padding: 20px;
}

.profile-photo-container {
    /* flex: 1;
    padding-left: 20px;
    text-align: center; */
    flex-basis: 30%;
        text-align: right;
        padding: 20px;
}

.small-button {
    font-size: 12px;
    /* Making font size smaller */
    padding: 4px 15px;
    background-color: white;
    /* Adjust padding to make overall button smaller */
    
}
@media only screen and (max-width: 768px) {

    .profile-header,
    .profile-intro,
    .profile-container {
        flex-basis: 100%;
        text-align: center;
    }

    .profile-container {
        order: -1;
        /* This will make the photo appear at the top on smaller screens */
    }
}



.profile-header,
.profile-intro,
.profile-container {
    flex-basis: 100%;
    text-align: left;
}

.profile-container {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.profile-photo-container {
    flex-basis: 30%;
    text-align: right;
    padding: 20px;

}

.small-button {
    font-size: 12px;
    padding: 4px 15px;
    background-color: white;
}

@media only screen and (max-width: 768px) {
    .profile-container {
        flex-direction: column-reverse;
        /* Stack items vertically and reverse the order */
    }

    .profile-photo-container {
        flex-basis: 100%;
        text-align: center;
    }

    .profile-header {
    flex-basis: 100%;
    text-align: center;
    }
}