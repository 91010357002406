.edit-event-modal .ant-modal-content {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.edit-event-modal .ant-modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.edit-event-modal .ant-modal-body {
    padding: 20px;
}

.edit-event-modal .ant-modal-footer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

/* Add your custom styling */
.custom-modal .ant-btn {
    /* Adjust button size as needed */
    padding: 5px 10px;
    font-size: 12px;
}